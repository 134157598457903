<template>
  <div class="auth__page" v-if="!loading">
    <div class="auth__header">
      <img class="logo" v-bind:src="data.logo.src" v-bind:alt="data.logo.name">
    </div>
    <div class="auth__content">
      <div class="auth__block">
        <div class="auth__title">
          <strong>{{ t('message.welcome') }} </strong> {{ t('message.auth_system') }}
        </div>
        <div class="auth__form">
          <form action="#" class="form" v-on:submit.prevent="login">
            <div class="form__separator"></div>
            <div class="form__row">
              <label class="form__label" for="login">{{ t('label.username') }}</label>
              <input class="form__input" type="text" name="login" id="login" v-model="loginParams.username">
            </div>
            <div class="form__row">
              <label class="form__label" for="password">{{ t('label.password') }}</label>
              <input class="form__input" type="password" name="password" id="password" v-model="loginParams.password">
            </div>
            <div class="form__row form__row--btns">
              <input class="btns btn-blue" type="submit" v-bind:value="t('button.login')">
            </div>
            <div class="form__row--error" v-if="signInError">
              {{ t('message.sign_in_error') }}
            </div>
          </form>
        </div>
      </div>
      <div class="auth__footer" v-html="data.footerCopyright"></div>
    </div>
  </div>
  <page-loader v-else></page-loader>
</template>

<script>
import {useStore} from 'vuex'
import {computed, ref, watchEffect, reactive} from 'vue'
import {useRouter} from "vue-router";
import {useI18n} from 'vue-i18n'
import PageLoader from "@/components/PageLoader";
import {ACTION_TYPES} from "@/constants";
import useAdmin from "@/helpers/useAdmin";

export default {
  name: 'admin-login',
  components: {PageLoader},
  setup() {
    const store = useStore();
    const signInError = computed(() => store.getters.isSignInError);
    const loading = ref(true);
    const data = computed(() => store.getters.data);
    const {redirectIfLoggedIn, adminLoaded} = useAdmin();

    const router = useRouter();
    watchEffect(() => {
      redirectIfLoggedIn(router);
      if (adminLoaded.value && data.value) {
        loading.value = false;
      }
    })

    const loginParams = reactive({
      username: null,
      password: null
    });

    const login = () => {
      loading.value = true;
      const data = {
        login: loginParams.username,
        password: loginParams.password,
      };
      store.dispatch(ACTION_TYPES.signIn, data);
    }
    const i18n = useI18n();

    return {t: i18n.t, loading, login, loginParams, signInError, data};
  }
}
</script>
